<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-allowance-parameter :allowanceParametor="allowanceParametor" @editForm="editForm"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
        <modal-allowance-pattern :allowancePatternData="allowancePatternData" @refresh="refresh"/>
        <modal-allowance-pattern-edit :id="id" :allowancePatternData="allowancePatternData" @refresh="refresh"/>
    </div>
</template>

<script>
import tableAllowanceParameter from '@/views/component/Table/tableAllowanceParameter.vue'
import ModalAllowancePattern from '@/views/component/Modal/ModalAllowanceParameter/ModalAllowancePattern.vue'
import ModalAllowancePatternEdit from '@/views/component/Modal/ModalAllowanceParameter/ModalAllowancePatternEdit.vue'

export default {
    components: {
        tableAllowanceParameter,
        ModalAllowancePattern,
        ModalAllowancePatternEdit
    },
    data() {
        return {
            showPreloader: false,
            allowanceParametor: [],
            allowancePatternData: [],
            id: null,
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'ID', thStyle: {width: '30px'}},
            ]
        }
    },
    mounted() {
        this.openFilter()
        this.getAllowanceData()
        this.$store.commit('pageData/setdataCount', null)
    },
    methods: {
        editForm(id) {
            this.id = id
            setTimeout(() => {
                this.openModalEdit()
            }, 0);
        },
        openModalEdit() {
            this.$bvModal.show('editModalAllowanceParameter')
        },
        getAllowanceData() {
            this.$http
                .get('v2/allowances/pattern/data')
                .then(res => {
                    this.allowancePatternData = res.data
                })
        },
        openFilter() {
                let allRoutes = this.$store.state.draggableTab.tabs
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                let arrayRoutes = []
                allRoutes.forEach(element => {
                    arrayRoutes.push(element.path)
                }); 
                if (arrayRoutes.includes(this.$route.path) == true){
                    this.showPreloader = true
                    this.$http
                    .get(`${this.$route.name}`,{params: param}).then(res => {
                        this.allowanceParametor = res.data
                        this.$store.commit('pageData/setdataCount', this.allowanceParametor.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
                }else if(arrayRoutes.includes(this.$route.path) == false){
            this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        sendToParent(tableData){
            this.allowanceParametor = tableData
            this.$store.commit('pageData/setdataCount', this.allowanceParametor.length)
        },
        refresh() {
            this.showPreloader = true
            this.$http
            .get(`${this.$route.name}`)
            .then(res => {
                this.allowanceParametor = res.data
                this.$store.commit('pageData/setdataCount', this.allowanceParametor.length)
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
        },
    },
    computed: {
       fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val) {
            if(val) {
                let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.$http
                .get(`${this.$route.name}`, {param: params})
                .then(res =>{
                    this.allowanceParametor = res.data
                    this.$store.commit('pageData/setdataCount', this.allowanceParametor.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }

}
</script>