<template>
        <b-modal 
        :id="$route.name + 'CreateModal'" 
        title="Дабавить шаблон"
        size="s"  
        hide-footer 
        mo-enforce-focus 
        :no-close-on-backdrop="true"
        centered
        @close="closeModal"
        >
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Имя: <i class="color">*</i></label>
                            <b-input size="sm"  type="text" v-model="dataAllowance.name"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Name: <i class="color">*</i></label>
                            <b-input size="sm" type="text" v-model="dataAllowance.code"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Описания: </label>
                            <b-form-textarea
                            v-model="dataAllowance.description"
                            placeholder="Описания"
                            rows="3"
                            max-rows="3"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Порядок: <i class="color">*</i></label>
                            <b-input size="sm" type="number" v-model="dataAllowance.sort"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Параметры клиента: </label>
                            <b-input size="sm" type="number" v-model="client_params"></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Тип: <i class="color">*</i></label>
                            <b-form-select 
                            v-model="dataAllowance.type" 
                            :options="allowancePatternData.types" 
                            size="sm"
                            value-field="key"
                            text-field="value"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                <b-col cols="6">
                    <div class="status">
                        <label>Админка</label>
                        <b-form-checkbox v-model="dataAllowance.admin_show" checked="true" name="check-button" switch inline>
                            {{ this.dataAllowance.admin_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="status">
                        <label>Сайт</label>
                        <b-form-checkbox v-model="dataAllowance.site_show"  checked="true" name="check-button" switch inline>
                            {{ this.dataAllowance.site_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="6">
                    <div class="status">
                        <label>Водитель</label>
                        <b-form-checkbox v-model="dataAllowance.performer_show" checked="true" name="check-button" switch inline>
                            {{ this.dataAllowance.performer_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="status">
                        <label>Клиент</label>
                        <b-form-checkbox v-model="dataAllowance.client_show" checked="true" name="check-button" switch inline>
                            {{ this.dataAllowance.client_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="6">
                    <img class="mw-100" v-if="dataAllowance.icon" :src="dataAllowance.icon">
                    <div class="status">
                        <label>Иконка</label>
                        <b-form-file browse-text="Добавить" accept=".svg" @change="uploadFile" placeholder="Выберите файл или перетащите его сюда...">
                        </b-form-file>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="status">
                        <label>Cтатус</label>
                        <b-form-checkbox v-model="dataAllowance.is_active" checked="true" name="check-button" switch inline>
                            {{ this.dataAllowance.is_active ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="d-flex justify-content-between">
                <b-button
                    :disabled="submitButtonDisabled"
                    class='mt-2 col-md-5'
                    variant="primary"
                    @click="createAllowancePattern">
                    Дабавить
                </b-button>
                <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                    Отмена
                </b-button>
            </b-col>
        </b-row>
        </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props: ['allowancePatternData'],
    data() {
        return {
            submitButtonDisabled: false,
            client_params: null,
            dataAllowance: {
                name: '',
                code: '',
                description: '',
                sort: null,
                client_params: [],
                type: null,
                site_show: false,
                client_show: false,
                performer_show: false,
                admin_show: false,
                is_active: null,
                icon: undefined,
            }
        }
    },
    methods: {
        uploadFile(event) {
            let file = event.target.files[0];
            this.dataAllowance.icon = URL.createObjectURL(file);
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.client_params = null,
            this.dataAllowance = {
                name: '',
                code: '',
                description: '',
                sort: null,
                client_params: [],
                type: null,
                site_show: false,
                client_show: false,
                performer_show: false,
                admin_show: false,
                is_active: false,
                icon: undefined,
            }
        },
        createAllowancePattern()  {
            Array.from(this.client_params).forEach(e => { this.dataAllowance.client_params.push(e) })
            this.dataAllowance.site_show = this.dataAllowance.site_show ? 1 : 0
            this.dataAllowance.client_show = this.dataAllowance.client_show ? 1 : 0
            this.dataAllowance.performer_show = this.dataAllowance.performer_show ? 1 : 0
            this.dataAllowance.admin_show = this.dataAllowance.admin_show ? 1 : 0
            this.dataAllowance.is_active = this.dataAllowance.is_active ? 1 : 0
            this.submitButtonDisabled = true
            this.$http.post('/v2/allowances/pattern', this.dataAllowance)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Cоздан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: res.data.message,
                    },
                })
                this.$emit('refresh')
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors, 
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        }
    }
</script>


<style scoped>
.color {
    color: red;
}
.status {
    display: flex;
    flex-direction: column;
}
</style>