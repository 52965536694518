<template>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            :items="allowanceParametor"
            :fields="fields"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              ></b-form-checkbox>
            </template>
            <template #cell(is_active)="data">
              {{ data.item.is_active ? 'Да' : 'Нет' }}
            </template>
            <template #cell(site_show)="data">
              {{ data.item.site_show ? 'Да' : 'Нет' }}
            </template>
            <template #cell(client_show)="data">
              {{ data.item.client_show ? 'Да' : 'Нет' }}
            </template>
            <template #cell(performer_show)="data">
              {{ data.item.performer_show ? 'Да' : 'Нет' }}
            </template>
            <template #cell(admin_show)="data">
              {{ data.item.admin_show ? 'Да' : 'Нет' }}
            </template>
            <template #cell(client_params)="data">
              <span v-for="client in data.item.client_params" :key="client.index">
                  {{ `${client},` }}
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ['allowanceParametor'],
  data() {
    return {
      id: null,
      fields: [
        { key: 'id', label: 'Id', sortable: true, thStyle: { width: '40px' } },
        { key: 'code', label: 'Код', sortable: true, thStyle: { width: '150px' } },
        { key: 'name', label: 'Имя', sortable: true, thStyle: { width: '150px' } },
        { key: 'description', label: 'Описания', sortable: true, thStyle: { width: '150px' } },
        { key: 'client_params', label: 'Параметры клиента', sortable: true, thStyle: { width: '150px' } },
        { key: 'is_active', label: 'Статус', sortable: true, thStyle: { width: '150px' } },
        { key: 'site_show', label: 'Сайт', sortable: true, thStyle: { width: '150px' } },
        { key: 'client_show', label: 'Клиент', sortable: true, thStyle: { width: '150px' } },
        { key: 'performer_show', label: 'Водитель', sortable: true, thStyle: { width: '150px' } },
        { key: 'admin_show', label: 'Админ', sortable: true, thStyle: { width: '150px' } },
      ],
    }
  },
  mounted() {
    resizeable()
  },
  methods:{
    editForm() {
      this.$emit('editForm', this.id)
    },
    clicked(item, index, event) {
      this.id = item.id
      this.$store.state.filterPlus.tableIndex = index

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest('td');

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
      cellSelect()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';

table {
  outline: none;
  table-layout: fixed;
  user-select: none;
  overflow: hidden;
  position: relative;
}

tr, td, th {
  padding: 0;
}

td, th {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  min-width: 60px;
}
</style>
